.backface-hidden {
    backface-visibility: hidden;
  }
  
.rotate-y-180 {
    transform: rotateY(180deg);
}

.preserve-3d {
    transform-style: preserve-3d;
}

@keyframes flip {
    from {
        transform: perspective(600px) rotateY(0deg);
    }
    to {
        transform: perspective(600px) rotateY(180deg);
    }
}

.flip {
    animation: flip 0.5s;
}
  
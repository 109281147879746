.present hr {
    margin: 1rem 0;
}

.present p {
    margin-bottom: 0.5rem;
}

.remove-margin-bottom {
    margin-bottom: 0 !important;
}

.present h1 {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
}
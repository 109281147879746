@keyframes heartBeat {
    0% {
        transform: scale(1);
    }

    14% {
        transform: scale(1.1);
    }

    28% {
        transform: scale(1);
    }

    42% {
        transform: scale(1.1);
    }

    70% {
        transform: scale(1);
    }
}
  
.heartBeat {
    animation-name: heartBeat;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
}

@keyframes zoomInDown {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
        -webkit-animation-timing-function: cubic-bezier(.55,.055,.675,.19);
        animation-timing-function: cubic-bezier(.55,.055,.675,.19);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
        -webkit-animation-timing-function: cubic-bezier(.175,.885,.32,1);
        animation-timing-function: cubic-bezier(.175,.885,.32,1);
    }
}

.zoomInDown {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: zoomInDown;
    animation-name: zoomInDown;
    animation-delay: 0.4s;
}

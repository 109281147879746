.item:nth-child(4n-3) {
  @apply self-start;
}
  
.item:nth-child(4n-2),
.item:nth-child(4n) {
  @apply self-center;
}

.item:nth-child(4n-1) {
  @apply self-end;
}
  